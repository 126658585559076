import { Paragraph } from "~/components/ui/typography";
import clsx from "clsx";
import React from "react";
interface InputErrorProps {
	id: string;
	children?: string | null;
	className?: string;
}

export function InputError({ children, id, className }: InputErrorProps) {
	if (!children) {
		return null;
	}

	return (
		<Paragraph
			id={id}
			className={className}
			size="body-xsmall"
			color="warning"
		>
			{children}
		</Paragraph>
	);
}

export type InputProps = JSX.IntrinsicElements["input"];

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
	function InputComponent({ className, ...rest }, ref) {
		return (
			<input
				ref={ref}
				className={clsx(
					"border-stroke bg-primary min-h-[44px] w-full rounded border border-solid px-5 py-3 focus-visible:border-grey-80 focus-visible:outline-none",
					className
				)}
				{...rest}
			/>
		);
	}
);
